import axios from 'axios';

export default class PaymentsApi {
  async getSalesStatisticsPaymentMethods() {
    const paymentMethods = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/payment-methods`
    );
    return paymentMethods.data;
  }

  async getPaymentSlipsCurrentMonth() {
    const paymentSlips = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/statistics/bank-slips-current-month`
    );
    return paymentSlips.data;
  }

  async getSalesStatisticsMonthly() {
    const salesStatisticsMonthly = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/monthly`
    );
    return salesStatisticsMonthly.data;
  }

  async getSalesStatisticsYearly() {
    const salesStatisticsYearly = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/yearly`
    );
    return salesStatisticsYearly.data;
  }

  async getSalesStatisticsWeekly() {
    const salesStatisticsWeekly = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/weekly`
    );
    return salesStatisticsWeekly.data;
  }

  async getSalesStatisticsDaily() {
    const salesStatisticsDaily = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/daily`
    );
    return salesStatisticsDaily.data;
  }

  async getSalesStatisticsHourly() {
    const salesStatisticsHourly = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/statistics/hourly`
    );
    return salesStatisticsHourly.data;
  }

  async getCreditsBalance() {
    const creditsBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/credits/balance`
    );
    return creditsBalance.data;
  }

  async getTransferBalance() {
    const currentBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transfers/balance`
    );
    return currentBalance.data;
  }

  async getTransferHistory(filter) {
    const currentBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transfers?startDate=${
        filter.startDate
      }&endDate=${filter.endDate}&index=${filter.index}`
    );
    return currentBalance.data;
  }

  async getTransferNext(filter) {
    const currentBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transfers/scheduled?startDate=${
        filter.startDate
      }&endDate=${filter.endDate}&index=${filter.index}`
    );
    return currentBalance.data;
  }

  async getTransferDetails(transferId) {
    const currentBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transfers/${transferId}`
    );
    return currentBalance.data;
  }

  async getTransferNextDetails(date) {
    const currentBalance = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transfers/scheduled/${date}`
    );
    return currentBalance.data;
  }

  async getTransferNextMovement(date, offset, types) {
    let path = `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/transfers/scheduled/${date}/movements?`;

    if (types !== null && types.length > 0) {
      types.forEach(type => {
        path += `&types=${type}`;
      });
    }

    path += `&index=${offset.index}&length=${offset.limit}`;
    const currentBalance = await axios.get(path);
    return currentBalance.data;
  }

  async getTransferCardMovement(transferId, offset) {
    const currentBalance = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/transfers/${transferId}/movements?types=cardTransaction&index=${
        offset.index
      }&length=${offset.limit}`
    );
    return currentBalance.data;
  }

  async getTransferBankSlipMovement(transferId, offset) {
    const currentBalance = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/transfers/${transferId}/movements?types=bankslip&index=${
        offset.index
      }&length=${offset.limit}`
    );
    return currentBalance.data;
  }

  async getTransferOtherMovement(transferId) {
    const currentBalance = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/transfers/${transferId}/movements?types=other`
    );
    return currentBalance.data;
  }

  async acceptTerm() {
    const anticipation = await axios.post(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations/accept-term`
    );
    return anticipation.data;
  }

  async canAnticipate() {
    const anticipation = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations/can-anticipate`
    );
    return anticipation.data;
  }

  async findAnticipation(anticipationId) {
    const anticipation = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations/${anticipationId}`
    );
    return anticipation.data;
  }

  async findAnticipationTransactions(anticipationId, offset) {
    const anticipation = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/anticipations/${anticipationId}/card-transactions?index=${
        offset.index
      }&${offset.limit}`
    );
    return anticipation.data;
  }

  async anticipationHistory(anticipationFilter) {
    const anticipation = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations?index=${
        anticipationFilter.offset.index
      }&${anticipationFilter.offset.limit}`
    );
    return anticipation.data;
  }

  async anticipateAllTransactions() {
    const transactionsAvailable = await axios.post(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/anticipations/new-request/all-card-transactions`
    );
    return transactionsAvailable.data;
  }

  async getTransactionsAvailable(anticipationFilter) {
    const transactionsAvailable = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/anticipations/card-transactions-available?index=${
        anticipationFilter.offset.index
      }&${anticipationFilter.offset.limit}`
    );
    return transactionsAvailable.data;
  }

  async getAnticipationsHistory(anticipationFilter) {
    const anticipation = await axios.post(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations/history`,
      { ...anticipationFilter }
    );
    return anticipation.data;
  }

  async getAnticipationInProgress() {
    const anticipation = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/anticipations/in-progress`
    );
    return anticipation.data;
  }

  async getAnticipationTransactions(anticipationId, transactionFilter) {
    const transactions = await axios.post(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/anticipations/${anticipationId}/transactions`,
      { ...transactionFilter }
    );
    return transactions.data;
  }

  async getTransactionsAnticipated(transactionFilter) {
    const transactions = await axios.post(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transactions/anticipated`,
      { ...transactionFilter }
    );
    return transactions.data;
  }

  async requestSelectedTransactions(transactions) {
    const anticipation = await axios.post(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/anticipations/new-request/selected-card-transactions`,
      transactions
    );
    return anticipation.data;
  }

  async registerBankSlipCredit(BankSlip) {
    const registers = await axios.post(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/payments/bankslips/credits/create`,
      BankSlip.list
    );
    return registers.data;
  }

  async bankSlipPdf(paymentId) {
    var response = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/bank-slips/pdf/${paymentId}`,
      { responseType: 'arraybuffer' }
    );

    if (response.status == 200) {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = 'boleto.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      var decodedString = String.fromCharCode.apply(
        null,
        new Uint8Array(response.data)
      );
      var obj = JSON.parse(decodedString);
      return obj;
    }
  }

  async singleBankSlipPdf(bankslipId) {
    const bankSlip = await axios
      .get(
        `${
          process.env.VUE_APP_PAYMENTS_API_URL
        }/bank-slips/pdf/single/${bankslipId}`,
        { responseType: 'arraybuffer' }
      )
      .then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'boleto.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  async printPdfBankSlipCredit(paymentList) {
    await axios
      .post(
        `${
          process.env.VUE_APP_PAYMENTS_API_URL
        }/payments/bankslips/credits/Pdf`,
        {
          paymentslips: paymentList,
        },
        { responseType: 'arraybuffer' }
      )
      .then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'boleto-creditos.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  async singleBankSlipSendToEmail(bankSlipNumber, customer) {
    const result = await axios.post(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/bank-slips/send/single/${bankSlipNumber}`,
      customer
    );
    return result.data;
  }

  async bankSlipSendToEmail(paymentId, customer) {
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/bank-slips/send/${paymentId}`,
      customer
    );
    return result.data;
  }

  async changeBankslip(number, bankSlip) {
    const registers = await axios.post(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/bank-slips/replace/${number}`,
      bankSlip
    );
    return registers.data;
  }

  async getHistoryPayment(filters) {
    var filterIndex = 0;
    var paymentMethodsIndex = 0;
    const bankSlip =
      filters.paymentMethod.bankSlip !== ''
        ? `paymentMethods[${paymentMethodsIndex}]=${
            filters.paymentMethod.bankSlip
          }`
        : '';
    if (bankSlip !== '') {
      paymentMethodsIndex++;
      filterIndex++;
    }
    const credit =
      filters.paymentMethod.credit !== ''
        ? `${
            filterIndex !== 0 ? '&' : ''
          }paymentMethods[${paymentMethodsIndex}]=${
            filters.paymentMethod.credit
          }`
        : '';
    if (credit !== '') {
      paymentMethodsIndex++;
      filterIndex++;
    }
    const debit =
      filters.paymentMethod.debit !== ''
        ? `${
            filterIndex !== 0 ? '&' : ''
          }paymentMethods[${paymentMethodsIndex}]=${
            filters.paymentMethod.debit
          }`
        : '';
    if (debit !== '') {
      paymentMethodsIndex++;
      filterIndex++;
    }
    var statusIndex = 0;
    const paid =
      filters.status.paid !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.paid
          }`
        : '';
    if (paid !== '') {
      statusIndex++;
      filterIndex++;
    }
    const delayed =
      filters.status.delayed !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.delayed
          }`
        : '';
    if (delayed !== '') {
      statusIndex++;
      filterIndex++;
    }
    const unauthorized =
      filters.status.unauthorized !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.unauthorized
          }`
        : '';
    if (unauthorized !== '') {
      statusIndex++;
      filterIndex++;
    }
    const pending =
      filters.status.pending !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.pending
          }`
        : '';
    if (pending !== '') {
      statusIndex++;
      filterIndex++;
    }
    const canceled =
      filters.status.canceled !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.canceled
          }`
        : '';
    if (canceled !== '') {
      statusIndex++;
      filterIndex++;
    }
    const contested =
      filters.status.contested !== ''
        ? `${filterIndex !== 0 ? '&' : ''}status[${statusIndex}]=${
            filters.status.contested
          }`
        : '';
    if (contested !== '') {
      statusIndex++;
      filterIndex++;
    }
    const date =
      filters.startDate !== '' && filters.endDate !== ''
        ? `${filterIndex !== 0 ? '&' : ''}startDate=${
            filters.startDate
          }&endDate=${filters.endDate}`
        : '';
    if (date !== '') {
      filterIndex++;
    }
    const payerTaxDocument =
      filters.payerTaxDocument !== ''
        ? `${filterIndex !== 0 ? '&' : ''}payerTaxDocument=${
            filters.payerTaxDocument
          }`
        : '';
    if (payerTaxDocument !== '') {
      filterIndex++;
    }
    const payerName =
      filters.payerName !== ''
        ? `${filterIndex !== 0 ? '&' : ''}payerName=${filters.payerName}`
        : '';
    if (payerName !== '') {
      filterIndex++;
    }
    const nsu =
      filters.nsu !== ''
        ? `${filterIndex !== 0 ? '&' : ''}nsu=${filters.nsu}`
        : '';
    if (nsu !== '') {
      filterIndex++;
    }
    const cardBrand =
      filters.cardBrand !== ''
        ? `${filterIndex !== 0 ? '&' : ''}cardBrand=${filters.cardBrand}`
        : '';
    if (cardBrand !== '') {
      filterIndex++;
    }
    const cardFinal =
      filters.cardFinal !== ''
        ? `${filterIndex !== 0 ? '&' : ''}cardFinal=${filters.cardFinal}`
        : '';
    if (cardFinal !== '') {
      filterIndex++;
    }
    const bankslipId =
      filters.bankslipId !== ''
        ? `${filterIndex !== 0 ? '&' : ''}bankslipId=${filters.bankslipId}`
        : '';
    if (bankslipId !== '') {
      filterIndex++;
    }
    const note =
      filters.note !== ''
        ? `${filterIndex !== 0 ? '&' : ''}note=${filters.note}`
        : '';

    const offset = `index=${filters.offset.index}&${filters.offset.limit}`;

    const fullQueryString = `${bankSlip}${credit}${debit}${paid}${delayed}${unauthorized}${pending}${canceled}${contested}${date}${payerTaxDocument}${payerName}${nsu}${cardBrand}${cardFinal}${bankslipId}${note}`;

    var result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/all${
        fullQueryString !== '' ? `?${fullQueryString}&${offset}` : `?${offset}`
      }`
    );
    return result.data;
  }

  async findPayment(paymentId) {
    const url = `${process.env.VUE_APP_PAYMENTS_API_URL}/find/${paymentId}`;
    const result = await axios.get(url);

    return result.data;
  }

  async cancelPayment(paymentNumber) {
    const url = `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/cancel/${paymentNumber}`;
    const result = await axios.post(url);

    return result.data;
  }

  async cancelBankslip(bankSlipNumber) {
    const url = `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/bank-slips/cancel/${bankSlipNumber}`;
    const result = await axios.post(url, {});

    return result.data;
  }

  async cancelCardTransaction(nsu) {
    const url = `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/card-transactions/cancel/${nsu}`;
    const result = await axios.post(url);

    return result.data;
  }

  async buyCredits(amount) {
    var url = `${process.env.VUE_APP_PAYMENTS_API_URL}/credits/buy`;
    await axios
      .post(url, { amount: amount }, { responseType: 'arraybuffer' })
      .then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'boleto-creditos.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  async fees() {
    const url = `${process.env.VUE_APP_PAYMENTS_API_URL}/fees`;
    const result = await axios.get(url);

    return result.data;
  }

  async defaultFees(daysPinpad, daysOnline, anticipated) {
    const url = `${
      process.env.VUE_APP_PAYMENTS_API_URL
    }/fees/default?daysPinpad=${daysPinpad}&daysOnline=${daysOnline}&anticipated=${anticipated}`;
    const result = await axios.get(url);

    return result.data;
  }

  async discountLevel() {
    const url = `${process.env.VUE_APP_PAYMENTS_API_URL}/fees/discount-level`;
    const result = await axios.get(url);

    return result.data;
  }

  async getFinancialMovements(filters) {
    const financialMovements = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/financial-movements?startDate=${
        filters.startDate
      }&endDate=${filters.endDate}&index=${filters.index}&length=${
        filters.length
      }`
    );

    return financialMovements.data;
  }

  async taxSummary(filter) {
    const taxSummary = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/reports/tax-summary?year=${
        filter.year
      }&index=${filter.offset.index}&length=${filter.length}`
    );

    return taxSummary.data;
  }

  async checkBlockedCardBrand(cardBrandName) {
    const blockedCardBrand = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_API_URL
      }/card-brands/check-block?cardBrandName=${cardBrandName}`
    );

    return blockedCardBrand.data;
  }

  async getDetailCard(nsu) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/card-transactions/${nsu}`
    );
    return result.data;
  }

  async getDetailBankSlip(bankSlipNumber) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/bank-slips/${bankSlipNumber}`
    );
    return result.data;
  }

  async getDetailDiverse(id) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/other-transfers/${id}`
    );
    return result;
  }

  async getListUsers(name) {
    let query = '';
    if (name.length > 0 || name !== null) {
      query += `?name=${name}`;
    }
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/splitters${query}`
    );
    return result;
  }

  async getUserSplitter(id) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/splitters/${id}`
    );
    return result.data;
  }

  async getReleases(params) {
    let query = `?&index=${params.index}&length=${params.length}`;
    if (params) {
      if (params.startDate.length > 0 && params.endDate.length > 0) {
        query += `&startDate=${params.startDate}&endDate=${params.endDate}`;
      }
    }
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transactions/future${query}`
    );
    return result.data;
  }

  async getDetailsReleases(filter) {
    filter.indice = filter.indice === undefined ? 0 : filter.indice;
    filter.length = filter.length === undefined ? 30 : filter.length;

    let query = `&index=${filter.indice}&length=${filter.length}`;
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENTS_API_URL}/transactions/future/${
        filter.date
      }/details?type=${filter.type}${query}`
    );
    return result.data;
  }
}
